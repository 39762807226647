import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { getSessionStore } from "app/utils/sessionStore";
import { ContactProfileDetailsContext } from "../macrocomponents/ContactLayout";
import { hexToRgb } from "../../utils/utils";
import plansLightSVG from "../../assets/plansLight.svg";
import plansDarkSVG from "../../assets/plansDark.svg";
import pendingLightSVG from "../../assets/pendingLight.svg";
import pendingDarkSVG from "../../assets/pendingDark.svg";
import { Tooltip } from "primereact/tooltip";

function ContactLeftPanelMenuItem({
  name,
  icon,
  directTo,
}: Readonly<{
  name: string;
  icon: string;
  directTo: string;
}>) {
  const { branding } = getSessionStore("lookUpResponse");
  const { primaryColor = "" } = branding ?? {};
  const { isOpen, checked } = useContext(ContactProfileDetailsContext);

  document.documentElement.style.setProperty(
    "--cta",
    primaryColor ? hexToRgb(primaryColor) : "0,36,70",
  );
  return (
    <li className="my-2 relative group w-full flex justify-center items-center">
      <NavLink
        to={directTo}
        className={
          ({ isActive }) =>
            `custom-target-icon flex items-center justify-center ${
              isOpen ? "w-full h-full" : "w-[46px] h-[48px]"
            } py-3 rounded-md  
        ${isActive ? "bg-cta text-white" : "hover:bg-gray-100 dark:hover:!bg-gray-800"}`
          // eslint-disable-next-line react/jsx-curly-newline
        }
        data-pr-tooltip={name}
      >
        {({ isActive }) => (
          <div className={`flex gap-2 items-center justify-center !bg-transparent `}>
            {name !== "Plans" && name !== "Pending" && (
              <i
                className={`pi ${icon}  ${isActive ? "text-white" : ""} `}
                style={{ fontSize: "1rem" }}
              />
            )}
            {name === "Plans" && (
              <img
                src={checked ? plansLightSVG : plansDarkSVG}
                data-pc-section="image"
                className="w-[1rem] h-[1rem]"
                alt="plans icon"
              />
            )}
            {name === "Pending" && (
              <img
                src={checked ? pendingLightSVG : pendingDarkSVG}
                data-pc-section="image"
                className="w-[1rem] h-[1rem]"
                alt="pending icon"
              />
            )}
            {isOpen && (
              <span
                className={`text-sm font-semibold text-left ${isOpen ? "w-20" : "w-0 hidden"}  ${
                  isActive ? "text-white" : ""
                }
              transition-all overflow-hidden`}
              >
                {name}
              </span>
            )}
          </div>
        )}
      </NavLink>
      {!isOpen && <Tooltip target=".custom-target-icon" className="tooltip-wrapper" />}
    </li>
  );
}

export default ContactLeftPanelMenuItem;
