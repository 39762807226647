import { ContactProfileDetailsContext } from "app/uicomponents/macrocomponents/ContactLayout";
import FilePreview from "app/uicomponents/macrocomponents/ProposalPending/FilePreview";
import { makeRequest } from "app/utils/makeRequest";
import { getSessionStore, setSessionStore } from "app/utils/sessionStore";
import { THEME, TOASTTYPE } from "app/utils/types";
import { CHAT_API_URL } from "app/utils/utils";
import { CopilotWidget, Root } from "iapp-neio";
import { Toast } from "primereact/toast";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface IChat {
  user: any;
  peers: any[];
  children?: any;
}

const Chat: FC<IChat> = ({ peers, user, children }) => {
  const [fileData, setFileData] = useState<{
    name: string;
    url: string;
  } | null>(null);
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [userChats, setUserChats] = useState<any[]>([]);

  const downloadAndSaveFile = (url: string, name?: string) =>
    fetch(url, { method: "get" })
      .then((response) => response.blob())
      .then((response) => {
        const href = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", name || "doc");
        document.body.appendChild(link);
        link.click();
      });

  const uploadFile = (file: File, onUploadProgress: (progressEvent: ProgressEvent) => void) => {
    const formData = new FormData();
    formData.append("file", file);

    return makeRequest(
      "chatFileUpload",
      {
        "Content-Type": "multipart/form-data",
      },
      {
        onUploadProgress,
      },
    )
      .post(formData)
      .then((res) => res.data.Key)
      .catch((err) => {
        if (err.message?.includes("image\\/png|image\\/jpeg|image\\/jpg|application\\/pdf")) {
          throw new Error("Please try uploading PDF/Word document/PNG/ JPG/JPEG files.");
        }
        throw new Error(err?.message ?? err);
      });
  };

  const deleteFile = (fileId: string) =>
    makeRequest("chatFileRemove").delete(encodeURIComponent(fileId), true) as any;

  const downloadFile = (fileId: string) =>
    makeRequest("chatFile")
      .get(encodeURIComponent(fileId), true)
      .then((res) => {
        res.data.data &&
          downloadAndSaveFile(res.data.data, fileId.substring(fileId.lastIndexOf("/") + 1));
      });

  const getPresignedUrl = (fileId: any) =>
    makeRequest("chatFile")
      .get(encodeURIComponent(fileId), true)
      .then((res) => res.data.data);

  const { pathname } = useLocation();
  const globalTheme = getSessionStore("globalTheme");
  const { checked } = useContext(ContactProfileDetailsContext);

  const onMessageReceived = (message: any) => {
    const sender = peers.find((peer) => peer.id === message?.data?.sender);
    if (!sender) {
      return;
    }

    if (message?.data?.message) {
      const msg = (message?.data?.message || "") as string;
      toast?.current?.show({
        severity: TOASTTYPE.INFO,
        content: (
          <div
            onClick={() => {
              const chatIcon = document.querySelector<HTMLSpanElement>(".chat-icon");

              if (chatIcon) {
                setSessionStore("showChatDot", false);
                chatIcon.classList.add("hidden");
              }
              navigate("/chat");
            }}
          >
            <div className="font-bold">
              {message?.data?.data?.externalId ? "" : `${sender?.lastName}, ${sender?.firstName}`}
            </div>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: message?.data?.data?.externalId
                  ? `${sender?.lastName}, ${sender?.firstName} has shared a file.`
                  : msg.substring(0, 20) + (msg.length > 20 ? "..." : "") ?? "",
              }}
            />
          </div>
        ),
        life: 3000,
        ...(globalTheme === THEME.DARK || checked
          ? {
              contentStyle: {
                background: "#333333",
                color: "#fff",
                borderColor: "#0b213f",
                borderLeftColor: "#0b213f",
              },
              style: {
                borderColor: "#0b213f",
                borderLeftColor: "#0b213f",
              },
              contentClassName: "chat-toast justify-between",
            }
          : { contentClassName: "justify-between" }),
      });
    }

    const chatIcon = document.querySelector<HTMLSpanElement>(".chat-icon");

    if (chatIcon) {
      setSessionStore("showChatDot", true);
      chatIcon.classList.remove("hidden");
    }
  };

  const onFileClick = (fileId: string) => {
    const fileName = fileId.substring(fileId.lastIndexOf("/") + 1);

    makeRequest("chatFile")
      .get(encodeURIComponent(fileId), true)
      .then((res) => {
        if (res.data.data) {
          const url = res.data.data;
          setFileData({
            name: fileName ?? "",
            url,
          });
        }
      });
  };

  useEffect(() => {
    const unreadCount = userChats
      .filter((el) => {
        const p1 = el?.user_conversations?.[0]?.participant_id;
        const p2 = el?.user_conversations?.[1]?.participant_id;

        let userToSearch = "";
        if (user.id === p1) {
          userToSearch = p2;
        } else {
          userToSearch = p1;
        }

        return peers.findIndex((p) => p.id === userToSearch) >= 0;
      })
      .reduce((acc, ch) => {
        if (ch.unread_count > 0) {
          acc += ch.unread_count;
        }
        return acc;
      }, 0);

    const chatIcon = document.querySelector<HTMLSpanElement>(".chat-icon");

    if (chatIcon && userChats.length > 0) {
      if (unreadCount > 0) {
        setSessionStore("showChatDot", true);
        chatIcon.classList.remove("hidden");
      } else {
        setSessionStore("showChatDot", false);
        chatIcon.classList.add("hidden");
      }
    }
  }, [userChats, user, peers]);

  return (
    <div className="chat-root h-full">
      <Root
        key={checked}
        options={{
          apiKey: "",
          apiUrl: CHAT_API_URL,
          user,
          peers: peers.filter((el) => el.id),
          fileOptions: {
            downloadOption: "direct",
            uploadFile,
            deleteFile,
            downloadFile,
            getPresignedUrl,
            onClick: onFileClick,
          },
          noWorkspace: null,
          loadingWorkspace: null,
          organization: "iapp",
          apiDomain: "zealert-api.westus2.cloudapp.azure.com",
          headless: pathname !== "/chat",
          onMessageReceived,
          features: {
            chatWithUsers: true,
            chatWithBot: false,
            chatWithGroup: false,
          },
          onLoadComplete(data) {
            setUserChats(data ?? []);
          },
          onMessageStatusUpdates(message) {
            if (message?.type === "message.new") {
              const chatIcon = document.querySelector<HTMLSpanElement>(".chat-icon");

              if (chatIcon) {
                setSessionStore("showChatDot", true);
                chatIcon.classList.remove("hidden");
              }
            } else {
              setUserChats((p: any[]) =>
                p?.map((el) => {
                  if (el?.id === message?.conversation_id) {
                    return {
                      ...el,
                      unread_count: 0,
                    };
                  }
                  return { ...el };
                }),
              );
            }
          },
          theme: {
            defaultTheme: {
              closeIcon: {},
            },
            customTheme:
              globalTheme === THEME.DARK || checked
                ? {
                    chatWindow: {
                      color: {
                        backgroundColor: "#0b213f",
                      },
                    },
                    peopleSearchBarChat: {
                      fontColor: {
                        searchTextColor: "#fff",
                      },
                      color: {
                        backgroundColor: "#0b213f",
                      },
                    },
                    searchedList: {
                      color: {
                        searchedListBg: "rgb(7, 20, 38)",
                        unreadMessagesBg: "#FF00ff",
                        selectedChatBg: "#f1f",
                      },
                      fontColor: {
                        personNameTextColor: "#fff",
                        lastMessageTextColor: "#fff",
                      },
                    },
                    listOfPeopleForChat: {
                      fontColor: {
                        noResultsFoundTextColor: "#fff",
                      },
                      color: {
                        backgroundColor: "rgb(255,205,255)",
                      },
                    },

                    AddNewChatModalTheme: {
                      color: {
                        headerBorder: "#71717A",
                        backgroundColor: "rgb(7, 20, 38)",
                        cancelIconColor: "#71717A",
                      },
                      fontColor: {
                        titleTextColor: "#fff",
                        subTitleTextColor: "#fff",
                        inputTextColor: "#fff",
                      },
                    },
                    searchedChat: {
                      color: {
                        chevronColor: "#fff",
                        searchedBorderColor: "#ff0000",
                      },
                      fontColor: {
                        textColor: "#fff",
                        emailColor: "#fff",
                        noMatchesFoundText: "#fff",
                      },
                    },

                    profileDisplay: {
                      color: {
                        backgroundColor: "rgb(7, 20, 38)",
                        titleBg: "#0b213f",
                      },
                      fontColor: {
                        titleColor: "#fff",
                      },
                    },
                    moreOptionsIcon: {
                      color: {
                        iconColor: "#fff",
                      },
                    },
                    blockModal: {
                      color: {
                        backgroundColor: "rgb(7, 20, 38)",
                        actionButtonBgColor: "#e24c4c",
                        cancelButtonBorder: "#71717A",
                        blockMenuBgColor: "#fff",
                        modalActionButtonBgColor: "#e24c4c",
                      },
                      fontColor: {
                        modalTitleColor: "#fff",
                        modalSubtitleColor: "#fff",
                        cancelTextColor: "#fff",
                        actionButtonTextColor: "#fff",
                      },
                    },

                    textArea: {
                      color: {
                        backgroundColor: "rgb(7, 20, 38)",
                      },
                      fontColor: {
                        typedTextColor: "#fff",
                      },
                    },
                    chatUploader: {
                      color: {
                        backgroundColor: "rgb(7, 20, 38)",
                      },
                      fontColor: {
                        fileNameTextColor: "#fff",
                      },
                    },

                    sentMessage: {
                      color: {
                        sentMessagePrimaryBackgroundColor: "rgb(7, 20, 38)",
                        sentMessageSecondaryBackgroundColor: "#F11",
                        sentMessageTextColor: "#fff",
                      },
                    },
                    replyMessage: {
                      color: {
                        replyMessagePrimaryBackgroundColor: "rgb(17, 40, 68)",
                        replyMessageSecondaryBackgroundColor: "#f11",
                        replyMessageTextColor: "#fff",
                      },
                    },
                    fileMessage: {
                      color: {
                        fileMessagePrimaryBackgroundColor: "#0e1b2c",
                        fileMessageSecondaryBackgroundColor: "#f11",
                        fileMessageTextColor: "#fff",
                      },
                    },

                    unsentMessage: {
                      color: {
                        unsentMessagePrimaryBackgroundColor: "rgb(175, 82, 82)",
                        unsentMessageRetryTextColor: "#fff",
                        unsentMessageSecondaryBackgroundColor: "#ff1",
                        unsentMessageTextColor: "#ff6259",
                        unsentMessageWarningTextColor: "#ff6259",
                      },
                    },

                    editMessageMenu: {
                      color: {
                        backgroundColor: "#1c1d20",
                        editIconColor: "#fff",
                        blockIconColor: "#fff",
                      },
                      fontColor: {
                        deleteTextColor: "#e24c4c",
                        EditTextColor: "#fff",
                      },
                    },

                    peopleSearchBox: {
                      color: {
                        borderColor: "red",
                        // borderColor: "rgb(7, 20, 38)",
                        textColor: "#f1f",
                        searchButtonColorBg: "#1581ED",
                        seachButtonBorder: "#1581ED",
                        backgroundColor: "rgb(210,111,25)",
                      },
                    },
                    blockIcon: {
                      color: {
                        iconColor: "#f1f",
                      },
                    },
                    fileLimitModal: {
                      color: {
                        backgroundColor: "#F1F",
                        buttonBgColor: "#1581ED",
                        buttonBorderColor: "#3B82F6",
                      },
                      fontColor: {
                        contentTextColor: "#6B7280",
                        buttonLabelColor: "#FFFFFF",
                      },
                    },
                    fileIcon: {
                      color: {
                        iconColor: "#fafafa",
                      },
                    },
                    downloadIcon: {
                      color: {
                        iconColor: "#fafafa",
                      },
                    },
                    moreVerticalIcon: {
                      color: {
                        iconColor: "#fff",
                      },
                    },
                    uploadIcon: {
                      color: {
                        arrowIconColor1: "#fff",
                        arrowIconColor2: "#fff",
                        baseIconColor: "#fff",
                      },
                    },
                    editIcon: {
                      color: {
                        iconColor: "#fff",
                      },
                    },
                    searchIcon: {
                      color: {
                        iconColor: "#fff",
                      },
                    },
                    doneIcon: {
                      color: {
                        iconColor: "#fff",
                      },
                    },
                    closeIcon: {
                      color: {
                        iconColor: "#fff",
                      },
                    },
                    closeUploadIcon: {
                      color: {
                        iconColor: "#fff",
                        rectColor: "#1c1d20",
                      },
                    },
                  }
                : {
                    peopleSearchBarChat: {
                      color: {
                        backgroundColor: "#fff",
                      },
                    },
                    sentMessage: {
                      color: {
                        sentMessagePrimaryBackgroundColor: "#e5e7eb",
                        sentMessageTextColor: "#000",
                      },
                    },
                  },
          },
        }}
      >
        <Toast ref={toast} position="top-right" className="cursor-pointer" />
        <CopilotWidget />
        {children}
        {fileData && (
          <FilePreview
            onCancel={() => {
              setFileData(null);
            }}
            url={fileData?.url || ""}
            loading
            docCategory={fileData?.name || ""}
          />
        )}
      </Root>
    </div>
  );
};

export default Chat;
